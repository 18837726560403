import styled, { css } from 'styled-components';

export const StyledIntro = styled.div`
  padding: 0 40px;
  width: 100%;
  flex-direction: row;
  ${(props) =>
    props.center &&
    css`
      display: grid;
      justify-content: center;
    `}
  ${(props) =>
    props.spaced &&
    css`
      display: flex;
      justify-content: space-between;
      @media (min-width: 320px) and (max-width: 550px) {
        flex-direction: column;
      }
    `}
  @media (min-width: 320px) and (max-width: 550px) {
    //phone
    flex-direction: column;
    justify-content: center;
    padding: 0 33px;
  }
  @media (min-width: 550px) and (max-width: 1024px) {
    padding: 0 30px;
  }
  .main-title {
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: ${(props) => props.theme.primaryText};
    text-transform: capitalize;
    margin-bottom: 10px;
    @media (min-width: 550px) and (max-width: 1024px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .sub-title {
    margin-top: 16px;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: ${(props) => props.theme.fadedGrey};
    opacity: 0.8;
    text-align: center;
    @media (min-width: 550px) and (max-width: 1024px) {
      font-size: 18px;
      line-height: 32px;
    }
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`;
