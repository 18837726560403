import Banner from 'components/home/Banner';
import DashboardBanner from 'components/home/DashboardBanner';
import Testimonial from 'components/home/Testimonial';
import Ad from 'components/shared/Ad';
import CourseCard from 'components/shared/CourseCard';
import { StyledCourseGrid } from 'components/styles/pricingStyle';
import { ALL_COURSES, ALL_PARTNERS, ALL_TESTIMONIALS } from 'lib/queries';
import { NextSeo } from 'next-seo';
import { shuffleFisherYates } from 'utils/functions';
import main from 'utils/mutationWrapper';

const Home = ({ auth, topCourses, testimonials }) => {
  return (
    <>
      <NextSeo canonical="/" />
      {auth?.firstName === undefined ? (
        <Banner />
      ) : auth?.firstName !== undefined && auth.enrolledCourses?.length > 0 ? (
        <Banner auth={auth} />
      ) : (
        <DashboardBanner auth={auth} />
      )}
      <div style={{ padding: '20px' }}>
        <StyledCourseGrid>
          {shuffleFisherYates(topCourses).map((activeCourse) => (
            <CourseCard course={activeCourse} key={activeCourse._id} centered />
          ))}
        </StyledCourseGrid>
      </div>
      {auth?.firstName === undefined && <Testimonial testimonials={testimonials} />}
      {auth?.firstName === undefined && <Ad />}
    </>
  );
};

export async function getStaticProps() {
  const { courses } = await main(ALL_COURSES, {
    input: {
      page: 0,
      limit: 20
    }
  });
  const { itemsList: topCourses } = courses;

  const { testimonials: apiTestimonials } = await main(ALL_TESTIMONIALS, {
    input: {
      page: 0,
      limit: 4
    }
  });
  const { itemsList: testimonials } = apiTestimonials;

  const { partners: apiPartners } = await main(ALL_PARTNERS, {
    input: {
      page: 0,
      limit: 4
    }
  });
  const { itemsList: partners } = apiPartners;

  return {
    props: {
      topCourses,
      testimonials,
      partners
    },
    revalidate: 1
  };
}

export default Home;
