import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import padlockIcon from 'public/images/padlock.svg';
import styled, { css } from 'styled-components';
import { calculateCourseDuration, truncateText } from 'utils/functions';

const StyledCourseCard = styled(motion.div)`
  height: 352px;
  width: 266px;
  padding: 16px;
  display: grid;
  grid-template-rows: 152px 1fr;
  grid-row-gap: 16px;
  background: ${(props) => props.theme.primaryText};
  border-radius: 8px;
  ${(props) =>
    props.centered &&
    css`
      @media (min-width: 320px) and (max-width: 550px) {
        justify-self: center;
      }
    `}

  .course-card-desc-container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    justify-content: space-between;
    height: 152px;
  }
  .course-card-picture {
    width: 234px;
    height: 152px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
  }
  .course-card-title > a {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: ${(props) => props.theme.cardBackground};
  }
  .course-card-sub-title {
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: ${(props) => props.theme.cardBackground};
    opacity: 0.8;
    margin-bottom: 8px;
  }
  .course-card-duration {
    opacity: 1;
  }
  .course-card-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .course-card-name a {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
    color: ${(props) => props.theme.cardBackground};
  }
  .course-card-lock {
    width: 24px;
    height: 24px;
  }
`;

const CourseCard = ({ course, margin, small, centered }) => {
  const formatDuration = (courseObject) => {
    const duration = calculateCourseDuration(courseObject);
    let formattedDuration = '';
    if (duration?.hours !== 0) {
      formattedDuration = `${formattedDuration} ${duration?.hours}h `;
    }
    if (duration?.minutes !== 0) {
      formattedDuration = `${formattedDuration} ${duration?.minutes}m `;
    }
    return formattedDuration;
  };

  return (
    <StyledCourseCard margin={margin} small={small} centered={centered}>
      <Link href={`/courses/${course.url}`} passHref>
        <a>
          <motion.img
            className="course-card-picture"
            src={course.coverImageUrl}
            whileHover={{
              scale: 1.03,
              transition: {
                duration: 0.2
              }
            }}
          />
        </a>
      </Link>
      <div className="course-card-desc-container">
        <div className="course-card-title">
          <Link href={`/courses/${course.url}`} passHref>
            <a>{truncateText(course.name, 50)}</a>
          </Link>
        </div>

        <div>
          <div className="course-card-sub-title">
            COURSE | {course.lessons?.length} {course.lessons?.length > 1 ? 'lessons' : 'lesson'} |
            <span className="course-card-duration">{formatDuration(course)}</span>
          </div>
          <div className="course-card-meta">
            <div className="course-card-name">
              <Link href={`/instructors/${course.author?.url}`} passHref>
                <a>
                  {course.author?.firstName} {course.author?.lastName}
                </a>
              </Link>
            </div>
            {course.member_only && (
              <Image
                className="course-card-lock"
                width={24}
                height={24}
                src={padlockIcon}
                alt="Lock icon"
              />
            )}
          </div>
        </div>
      </div>
    </StyledCourseCard>
  );
};

export default CourseCard;
