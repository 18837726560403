import Carousel from '@brainhubeu/react-carousel';
import Spinner from 'components/shared/spinner';
import Image from 'next/image';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
  width: 100%;
  padding-top: 40px;
  background-color: ${({ theme }) => theme.testimonialBg};
  box-shadow: 0px 33px 75px rgba(82, 11, 74, 0.11),
    0px 7.37098px 27.6497px rgba(82, 11, 74, 0.0632114),
    0px 2.19453px 11.5427px rgba(82, 11, 74, 0.0405754);
  margin-bottom: 128px;
  @media (max-width: 1024px) {
    margin-bottom: 72px;
  }

  .intro {
    font-family: 'DM Serif Display', serif;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-transform: capitalize;
    color: ${(props) => props.theme.fadedGret};
  }
  .testimonials {
    width: 100%;
    height: 370px;
    padding: 0 70px;
    @media (min-width: 320px) and (max-width: 870px) {
      height: 748px;
      padding: 0 33px;
    }
  }
  .testimonial {
    width: 100%;
    height: 100%;
    display: grid;
    grid-column-gap: 100px;
    grid-template-columns: 1fr 2fr;
    @media (min-width: 870px) and (max-width: 1024px) {
      grid-column-gap: 25px;
    }
    @media (min-width: 320px) and (max-width: 870px) {
      grid-gap: 20px 0;
      grid-template-rows: 1fr 4fr;
      grid-template-columns: none;
    }
  }
  .picture {
    justify-self: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    width: 290px !important;
    height: auto !important;
    border-radius: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    object-fit: cover;
    min-width: initial !important;
    max-width: initial !important;
    min-height: initial !important;
    @media (min-width: 320px) and (max-width: 870px) {
      width: 289px;
      height: 289px;
    }
  }
  .comment-group {
    align-self: center;
  }

  .comment {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.fadedGrey};
  }
  .name {
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
  }
  .BrainhubCarousel__dots {
    @media (min-width: 320px) and (max-width: 870px) {
      margin-top: -20px;
    }
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot {
    padding: 10px 5px;
  }
  .BrainhubCarousel__dot:before {
    width: 13px;
    height: 8px;
    background: ${(props) => props.theme.ash};
    border-radius: 4px;
  }
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
    width: 47px;
    height: 8px;
    background: ${(props) => props.theme.primaryLink};
    border-radius: 4px;
  }
  @media (min-width: 320px) and (max-width: 870px) {
    .BrainhubCarousel__trackContainer,
    .BrainhubCarouselItem.BrainhubCarouselItem--active {
      height: 740px;
    }
  }
  .BrainhubCarousel__dots {
    justify-content: flex-end;
  }
`;

function Testimonial({ testimonials }) {
  return (
    <StyledTestimonial>
      {status === 'LOADING' ? (
        <Spinner />
      ) : (
        <>
          <div className="intro">What People are Saying</div>
          <div className="testimonials">
            <Carousel
              centered
              infinite
              dots
              slidesPerPage={1}
              autoPlay={10000}
              animationSpeed={1000}>
              {testimonials &&
                testimonials.map((testimonial) => (
                  <div className="testimonial" key={testimonial._id}>
                    <Image
                      src={testimonial.imageUrl}
                      className="picture"
                      height="300"
                      width="300"
                      alt="Testifier"
                    />
                    <div className="comment-group">
                      <div className="comment">{testimonial.description}</div>
                      <div className="name">{`${testimonial.name}, ${testimonial.title}`}</div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </>
      )}
    </StyledTestimonial>
  );
}

export default Testimonial;
