import { USER_ENROLLED_COURSES } from 'lib/queries';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import main from 'utils/mutationWrapper';

const StyledWatchHistoryBanner = styled.div`
  width: 100%;
  padding: 75px 40px;
  height: 250px;
  background: ${({ theme }) =>
    `linear-gradient(270deg, ${theme.primaryLink} 2.58%, ${theme.headerBackground} 119.34%)`};
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 32px 20px;
  }
  .intro {
    font-family: 'DM Serif Display', serif;
    height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 320px) and (max-width: 550px) {
      flex-direction: column;
    }
  }
  .caption {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    align-items: center;
    letter-spacing: 0.05em;
    color: ${(props) => props.theme.primaryText};
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }
  .caption-desc {
    font-family: Montserrat;
    margin-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: ${(props) => props.theme.primaryText};
    opacity: 0.8;
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .courses {
    margin-top: 50px;
    @media (min-width: 320px) and (max-width: 550px) {
      margin-top: 125px;
    }
  }
  .carousel-nav {
    width: 50px !important;
    height: 50px !important;
    min-width: initial !important;
    min-height: initial !important;
    max-width: initial !important;
    max-height: initial !important;
    cursor: pointer;
    position: initial !important;
  }
  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    margin: 0 -50px;
    margin: 0 -50px 0 0;
  }
  .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft,
  .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
    z-index: 100;
    @media (min-width: 320px) and (max-width: 550px) {
      display: none;
    }
  }
`;

const WatchHistoryBanner = ({ auth }) => {
  const [cookies] = useCookies([]);
  const [, setWatchHistory] = useState([]);
  const [, setSlidesPerPage] = useState(2);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1280);

  const fetchWatchHistory = useCallback(async () => {
    const { enrolledCourses: userWatchHistory } = await main(USER_ENROLLED_COURSES, null, cookies);
    setWatchHistory(userWatchHistory);
  }, []);

  useEffect(() => {
    fetchWatchHistory();
  }, []);

  useEffect(() => {
    if (window) {
      const handleResize = () => setWidth(window.innerWidth);
      if (width > 1260) {
        setSlidesPerPage(2);
      } else if (width > 1000 && width <= 1260) {
        setSlidesPerPage(2);
      } else {
        setSlidesPerPage(1);
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });

  return (
    <StyledWatchHistoryBanner>
      <div className="intro">
        <div>
          <div className="caption has-auth">
            Welcome back,{' '}
            <span className="primary">
              {auth &&
                auth.firstName &&
                auth.firstName.replace(/^./, auth.firstName[0].toUpperCase())}
            </span>
          </div>
          {/* <div className="caption-desc">Continue learning</div> */}
        </div>
        <div>
          {/* <Link href="/history" passHref>
            <InvertedRoundButton bannerButton className="banner-btn" as="a">
              My Watch History
            </InvertedRoundButton>
          </Link> */}
        </div>
      </div>
      {/* <div className="courses">
        <Carousel
          slidesPerPage={slidesPerPage}
          autoPlay={5000}
          infinite
          animationSpeed={2000}
          arrowLeft={
            watchHistory.length > 2 ? (
              <Image alt="Scroll Left" className="carousel-nav" src={scrollLeftIcon} />
            ) : null
          }
          arrowRight={
            watchHistory.length > 2 ? (
              <Image alt="Scroll Right" className="carousel-nav" src={scrollRightIcon} />
            ) : null
          }
          addArrowClickHandler>
          {watchHistory &&
            watchHistory.map(
              (history) =>
                history.course && (
                  <HistoryCourseCard
                    id={history._id}
                    key={history._id}
                    course={history.course}
                    margin="0 40px 0 0"
                    isCompleted={history.isCompleted}
                  />
                )
            )}
        </Carousel>
      </div> */}
    </StyledWatchHistoryBanner>
  );
};

export default WatchHistoryBanner;
