import styled from 'styled-components';

export const StyledBanner = styled.div`
  margin-top: -40px;
  @media (min-width: 760px) and (max-width: 1025px) {
    //tablet
  }
  @media (max-width: 759px) {
    //phone
    display: grid;
    place-items: center;
  }
  .banner-bg {
    height: 524px;
    width: 100%;
    background-color: ${(props) => props.theme.headerBackground};
    padding: 116px 142px 48px 142px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      height: 400px;
      padding: 88px 35px 48px 35px;
    }
    @media (max-width: 759px) {
      //phone
      padding: 88px 16px 32px 16px;
      height: 352px;
    }
  }
  .clear-both {
    clear: both;
  }
  .pricing-bg {
    background-color: ${(props) => props.theme.primaryText};
    box-shadow: 4px -6px 15px 2px rgba(0, 0, 0, 0.28);
    border-radius: 25px;
    width: 370px;
    height: 528px;
    float: right;
    margin-top: -436px;
    margin-right: 142px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      margin-right: 35px;
      margin-top: -336px;
    }
    @media (max-width: 759px) {
      //phone
      width: 100%;
      margin: 32px 16px;
      float: none;
    }
  }
  .banner-text {
    height: 100%;
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      width: 323px;
    }
    @media (max-width: 759px) {
      //phone
      width: 100%;
    }
  }
  .banner-title {
    font-family: 'DM Serif Display', sans-serif;
      format('woff2');
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 0.02em;
    max-width: 323px;
    color: ${(props) => props.theme.primaryText};
    span {
      color: ${(props) => props.theme.success};
      margin-bottom: 10px;
    }
    @media (min-width: 760px) and (max-width: 1025px) {
      font-size: 30px;
      margin-bottom: 18px;
    }
    @media (max-width: 759px) {
      font-size: 30px;
      margin-bottom: 15px;
      max-width: 100%;
      text-align: center;
      span {
        color: ${(props) => props.theme.primaryText};
      }
    }
  }
  .banner-subtitle {
    margin-top: 18px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    opacity: 0.8;
    max-width: 296px;
    color: ${(props) => props.theme.primaryText};
    @media (min-width: 760px) and (max-width: 1025px) {
      font-size: 18px;
      margin-top: 0;
    }
    @media (max-width: 759px) {
      font-size: 16px;
      text-align: center;
      max-width: 100%;
    }
  }
  .ant-btn-primary {
    color: ${(props) => props.theme.primaryText};
    background: ${(props) => props.theme.primaryLink};
    border-color: ${(props) => props.theme.primaryLink};
  }
  .banner-switch {
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
    }
    @media (max-width: 759px) {
      //phone
      display: grid;
      place-items: center;
    }
    .switch-light.switch-candy {
      height: 54px;
      width: 370px;
      @media (max-width: 759px) {
        //phone
        width: 320px;
      }
      .plan1,
      .plan2 {
        height: 54px;
        width: 185px;
        display: grid;
        place-items: center;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-shadow: none;
        font-weight: normal;
        @media (max-width: 759px) {
          //phone
          width: 160px;
        }
      }
      .plans-box {
        border-radius: 32px;
        background: ${(props) => props.theme.primaryText};
        .active {
          color: ${(props) => props.theme.primaryText};
        }
        .inactive {
          color: ${(props) => props.theme.cardBackground};
          opacity: 0.8;
        }
      }

      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        width: 320px;
        .plan1,
        .plan2 {
          width: 160px;
        }
      }
      @media (max-width: 759px) {
        //phone
      }
    }
  }
  .pricing-bg {
    .savings {
      background: ${(props) => props.theme.success};
      width: 105px;
      height: 34px;
      border-radius: 0 25px 0 12px;
      color: ${(props) => props.theme.primaryText};
      display: grid;
      place-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
    }
    .savings-box {
      display: grid;
      justify-content: end;
    }
    .pricing-box {
      padding: 28px 24px 42px 24px;
      display: grid;
      place-items: center;
      .plan-name {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: ${(props) => props.theme.cardBackground};
        padding-top: 20px;
      }
      .price {
        padding-top: 32px;
        font-weight: normal;
        font-size: 56px;
        line-height: 40px;
        text-align: center;
        color: ${(props) => props.theme.cardBackground};
      }
      .price-comment {
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.02em;
        color: ${(props) => props.theme.black};
        padding-top: 16px;
        span {
          color: ${(props) => props.theme.primaryLink};
        }
      }
      .price-desc {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.02em;
        color: ${(props) => props.theme.cardBackground};
        opacity: 0.8;
        padding-top: 16px;
      }
      .success-btn {
        margin-top: 32px;
        width: 100%;
      }
      .monthly-btn {
        margin-top: 32px;
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: ${(props) => props.theme.cardBackground};
        border: 2px solid ${(props) => props.theme.cardBackground};
        box-sizing: border-box;
        border-radius: 50px;
      }
    }
    .pricing-switch {
      .switch-light.switch-candy {
        height: 32px;
        width: 169px;
        .plan1,
        .plan2 {
          height: 32px;
          width: 84px;
          display: grid;
          place-items: center;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.02em;
          text-shadow: none;
          font-weight: normal;
        }
        .plans-box {
          border-radius: 32px;
          background: ${(props) => props.theme.primaryText};
          box-shadow: 0px 3px 10.5px -1px rgba(172, 0, 93, 0.25);
          .active {
            color: ${(props) => props.theme.primaryText};
          }
          .inactive {
            color: ${(props) => props.theme.cardBackground};
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const StyledFeatures = styled.div`
  background: ${(props) => props.theme.black};
  padding: 0 40px;
  @media (min-width: 760px) and (max-width: 1025px) {
    //tablet
  }
  @media (max-width: 759px) {
    //phone
    padding: 0 22px;
  }
  .spacer-ft-top {
    width: 100%;
    height: 80px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
    }
    @media (max-width: 759px) {
      //phone
      height: 36px;
    }
  }
  .spacer-ft-bottom {
    width: 100%;
    height: 48px;
  }
  .ft-title {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
    }
    @media (max-width: 759px) {
      //phone
      font-size: 18px;
      line-height: 26px;
    }
  }
  .ft-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(370px, 1fr));
    place-items: center;
    grid-gap: 24px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    }
    @media (max-width: 759px) {
      //phone
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }
  }
  .ft-item {
    width: 384px;
    height: 310px;
    background-color: ${(props) => props.theme.lighterGrey};
    display: grid;
    place-items: center;
    padding: 54px 30px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      width: 370px;
    }
    @media (max-width: 759px) {
      //phone
      width: 320px;
    }
  }
  .ft-desc {
    font-size: 18px;
    line-height: 26px;
    padding-top: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
    @media (max-width: 759px) {
      //phone
      font-size: 16px;
    }
  }
`;
export const SpacerTestimonial = styled.div`
  width: 100%;
  height: 80px;
`;

export const StyledTeamFeatures = styled.div`
  background: linear-gradient(89.94deg, rgba(172, 0, 93, 0.58) 0.06%, rgba(26, 32, 43, 0.7) 99.95%);
  box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.25);
  height: 604px;
  margin: 160px 0;
  padding: 158px 0 64px 142px;
  @media (min-width: 760px) and (max-width: 1025px) {
    //tablet
    height: 468px;
    padding: 64px 0 48px 35px;
  }
  @media (max-width: 759px) {
    //phone
    height: 804px;
    padding: 48px 50px;
  }
  .team-ft-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 51px;
    height: 100%;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      height: 270px;
    }
    @media (max-width: 759px) {
      //phone
      grid-template-columns: none;
      grid-template-rows: 1fr 2fr;
      height: 548px;
    }
  }
  .team-ft-title {
    font-family: 'DM Serif Display', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 60px;
    max-width: 486px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryText};
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      max-width: 307px;
      font-size: 30px;
      line-height: 44px;
    }
    @media (max-width: 759px) {
      //phone
      width: 100%;
      max-width: 100%;
      font-size: 30px;
      line-height: 44px;
    }
  }
  .team-ft-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    max-width: 486px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      margin-top: 16ox;
      max-width: 314px;
      font-size: 18px;
      line-height: 32px;
    }
    @media (max-width: 759px) {
      //phone
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 28px;
    }
  }
  .team-ft-actions {
    margin-top: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      display: none;
    }
    @media (max-width: 759px) {
      //phone
      display: none;
    }
  }
  .team-ft-actions-mobile {
    display: none;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      margin-top: 35px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      width: 530px;
    }
    @media (max-width: 759px) {
      //phone
      margin-top: 32px;
      display: grid;
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
      justify-content: center;
      grid-row-gap: 32px;
      width: 100%;
    }
  }
  .team-ft-primary {
    width: 249px;
    height: 48px;
    background: ${(props) => props.theme.primaryText};
    border-radius: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryLink};
    display: grid;
    place-items: center;
  }
  .team-ft-secondary {
    width: 249px;
    height: 48px;
    background: transparent;
    border-radius: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryText};
    border: 2px solid ${(props) => props.theme.fadedGrey};
    display: grid;
    place-items: center;
  }
  .team-ft-bg {
    height: 100%;
    background: url('/images/team-ft-bg.png');
    background-size: cover;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      background: url('/images/team-ft-bg-tab.png');
      background-size: cover;
    }
    @media (max-width: 759px) {
      //phone
      background: url('/images/team-ft-bg-mobile.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

export const StyledCourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
`;
