import styled from 'styled-components';

import { InvertedRoundButton } from '@/design-system/buttons';

import { StyledIntro } from './intro';

const StyledPartners = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.newBg};
  padding: 104px 72px;
  padding-top: 0;
  justify-content: center;
  @media (min-width: 550px) and (max-width: 1024px) {
    padding: 0 20px 32px 20px;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 0 20px 32px 20px;
  }
`;

const StyledAdsGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 24px;
  margin-top: 64px;
  @media (min-width: 320px) and (max-width: 550px) {
    margin-top: 32px;
  }
`;

function Partners() {
  return (
    <StyledPartners>
      <StyledIntro centered>
        <div className="main-title">Train on Brytahub</div>
        <div className="sub-title">
          We partner with experts across many fields to provide indepth practical training for our
          students. Sounds interesting? Show us what you do
        </div>
      </StyledIntro>
      <StyledAdsGrid>
        <InvertedRoundButton large className="banner-btn" href="mailto:support@brytahub.com">
          Send us a mail
        </InvertedRoundButton>
      </StyledAdsGrid>
    </StyledPartners>
  );
}

export default Partners;
