import { motion } from 'framer-motion';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { InvertedRoundButton } from '@/design-system/buttons';

import WatchHistoryBanner from './WatchHistoryBanner';

export const StyledBanner = styled.div`
  height: 548px;
  width: 100%;
  background: ${({ theme }) =>
    `linear-gradient(270deg, ${theme.primaryLink} 2.58%, ${theme.headerBackground} 119.34%)`};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: none;
  padding: 0 40px;
  ${(props) =>
    props.otherPages &&
    css`
      grid-template-columns: 1fr;
      .banner-btn-container.course {
        display: grid;
        justify-content: center;
      }
    `}
  .caption-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-row: 1;
  }
  .caption {
    font-family: 'DM Serif Display', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 88px;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
    ${(props) =>
      props.otherPages &&
      css`
        max-width: unset;
      `}
  }
  .caption.has-auth {
    max-width: none;
  }
  .banner-img {
    align-self: center;
    width: auto;
    height: auto;
  }
  .caption-desc {
    font-family: 'Montserrat', sans-serif;
    max-width: 459px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    margin-top: 22px;
    ${(props) =>
      props.otherPages &&
      css`
        max-width: unset;
      `}
  }
  .banner-btn {
    margin-top: 68px;
  }
  .banner-btn-desc {
    font-family: 'Montserrat', sans-serif;
    width: 271px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 16px;
    margin-bottom: 48px;
    color: ${(props) => props.theme.primaryText};
  }
  .caption-desc.has-auth {
    font-size: 28px;
    line-height: 45px;
  }
  .primary {
    color: ${(props) => props.theme.primaryLink};
  }
  @media (min-width: 320px) and (max-width: 550px) {
    //phone
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    grid-row-gap: 30px;
    padding: 40px 10px;
    ${(props) =>
      props.otherPages &&
      css`
        grid-template-rows: initial;
        grid-row-gap: 0;
      `}
    .caption-div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row: 2;
      text-align: center;
    }
    .caption {
      max-width: 369px;
      font-family: 'DM Serif Display', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 56px;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: ${(props) => props.theme.primaryText};
      max-width: 323px;
      align-self: center;
    }
    .banner-img {
      align-self: center;
      max-width: 100%;
      height: 152px;
      justify-self: center;
    }
    .caption-desc {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.04em;
      color: ${(props) => props.theme.primaryText};
      margin-top: 8px;
    }
    .caption-desc.has-auth {
      font-size: 14px;
      line-height: 25px;
    }
    .banner-btn-container {
      display: grid;
      justify-content: center;
    }
    .banner-btn {
      margin-top: 38px;
    }
    .primary {
      color: ${(props) => props.theme.primaryLink};
    }
  }
  @media (min-width: 550px) and (max-width: 1024px) {
    //tabs
    height: 400px;
    width: 100%;
    background-color: ${(props) => props.theme.cardBackground};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    padding: 0 35px;
    grid-column-gap: 14px;
    ${(props) =>
      props.otherPages &&
      css`
        grid-template-columns: unset;
      `}
    .caption-div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row: 1;
    }
    .caption {
      margin-top: 15px;
      max-width: 369px;
      font-family: 'DM Serif Display', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 51px;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: ${(props) => props.theme.primaryText};
    }
    .banner-img {
      align-self: center;
      max-width: 100%;
    }
    .caption-desc {
      max-width: 369px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.04em;
      color: ${(props) => props.theme.fadedGrey};
      margin-top: 15px;
      ${(props) =>
        props.otherPages &&
        css`
          max-width: unset;
        `}
    }
    .caption-desc.has-auth {
      font-size: 16px;
      line-height: 25px;
    }
    .banner-btn {
      margin-top: 50px;
    }
    .primary {
      color: ${(props) => props.theme.primaryLink};
    }
    .success {
      color: ${(props) => props.theme.success};
    }
  }
`;

const Banner = ({ auth }) => {
  if (auth && auth.firstName && auth.enrolledCourses?.length > 0) {
    return <WatchHistoryBanner auth={auth} />;
  }
  return (
    <StyledBanner>
      <div className="caption-div">
        <motion.div
          className="caption"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              scale: 0.8,
              opacity: 0
            },
            visible: {
              scale: 1,
              opacity: 1,
              transition: {
                delay: 0.4,
                duration: 0.4
              }
            }
          }}>
          Unlock Learning
        </motion.div>
        <motion.div
          className="caption-desc"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              scale: 0.6,
              opacity: 0
            },
            visible: {
              scale: 1,
              opacity: 1,
              transition: {
                delay: 0.4,
                duration: 0.6
              }
            }
          }}>
          Learn vocational skills from industry experts with our indepth courses and extensive
          learning paths
        </motion.div>
        <motion.div
          className="banner-btn-container"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              scale: 0.8,
              opacity: 0
            },
            visible: {
              scale: 1,
              opacity: 1,
              display: 'flex',
              transition: {
                delay: 0.4,
                duration: 0.8
              }
            }
          }}>
          <Link passHref href="/search">
            <InvertedRoundButton bannerButton className="banner-btn">
              Browse Courses
            </InvertedRoundButton>
          </Link>
        </motion.div>
      </div>
      <motion.img
        src="/images/banner.webp"
        alt="Banner"
        className="banner-img"
        width="500"
        height="300"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1,
            transition: {
              delay: 0.8,
              duration: 0.8
            }
          }
        }}
      />
    </StyledBanner>
  );
};

export default Banner;
